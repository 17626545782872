/*import "bootstrap-sass/assets/javascripts/bootstrap/transition.js";
import "bootstrap-sass/assets/javascripts/bootstrap/affix.js";
import "bootstrap-sass/assets/javascripts/bootstrap/alert.js";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip.js";
import "bootstrap-sass/assets/javascripts/bootstrap/modal.js";
import "bootstrap-sass/assets/javascripts/bootstrap/button.js";
import "bootstrap-sass/assets/javascripts/bootstrap/popover.js";
import "bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js";
import "bootstrap-sass/assets/javascripts/bootstrap/collapse.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tab.js";*/

import 'jquery';
import 'vendor/dropotron.js'
import 'vendor/skel.js'
import 'vendor/skel-layers.js'

const initBootstrap = () => {
    // Bootstrap components are imported above
};

const initSkel = () => {
    if(!window.skel){
        console.error("window.skel is missing!");
        return;
    }
    return window.skel.init({
        reset: "full",
        breakpoints: {
            global: {
                range: "*",
                href: "/etc.clientlibs/annualreport-cdn/clientlibs/cdn/resources/css/main.css"
            },
            desktop: {
                range: "701-",
                href: "/etc.clientlibs/annualreport-cdn/clientlibs/cdn/resources/css/style-desktop.css",
                containers: 1170,
                grid: {
                    gutters: 0
                }
            },
            lndscpe: {
                range: "701-1080",
                href: "/etc.clientlibs/annualreport-cdn/clientlibs/cdn/resources/css/style-landscape.css"
            },
            mobile: {
                range: "-700",
                href: "/etc.clientlibs/annualreport-cdn/clientlibs/cdn/resources/css/style-mobile.css",
                containers: "100%!",
                grid: {
                    collapse: !0,
                    gutters: 20
                },
                viewport: {
                    scalable: !1
                }
            }
        },
        plugins: {
            layers: {
                config: {
                    mode: "transform"
                },
                navPanel: {
                    hidden: !0,
                    breakpoints: "mobile",
                    position: "top-left",
                    side: "left",
                    animation: "pushX",
                    width: "80%",
                    height: "100%",
                    clickToHide: !0,
                    html: '<div data-action="navList" data-args="nav"></div>',
                    orientation: "vertical"
                },
                titleBar: {
                    breakpoints: "mobile",
                    position: "top-left",
                    side: "top",
                    height: 71,
                    width: "100%",
                    html: '<span class="toggle" data-action="toggleLayer" data-args="navPanel"></span><span class="title" data-action="copyHTML" data-args="logo"></span>'
                }
            }
        }
    })
}

const initNav = () => {
    // Your navigation initialization code here
    window.$('#nav > ul').dropotron({
        mode: 'fade',
        noOpenerFade: true,
        speed: 300,
        alignment: 'center'
    });
    window.$('#header').show();
    window.$('#nav').show();
};

const initWindowLoad = () => {
    window.$("body").addClass('is-loading');
    window.$(window).on('load', () => $("body").removeClass('is-loading'));
};

export default { initBootstrap, initNav, initSkel, initWindowLoad };
