// Importing required libraries
//import 'jquery';
//import 'harvey';
//import 'lodash';

// Importing the utilities module
import utils from './utils.js';

// Load all the fun Bootstrap plugins
utils.initBootstrap();

// Initiate the skel responsive stuff
utils.initSkel();

// Add loading class to body
utils.initWindowLoad();

// Unhide the nav and do the jumbotron on it (this eliminates the flicker effect)
// Does this have to be in doc ready? Not sure
utils.initNav();
